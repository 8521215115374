import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.scss";
import { Place, Share,EmptyHeart,FullHeart } from "../../../utils/iconsSvg";
import ReactPlayer from "react-player";
import Axios from "axios";
import {
    ButtonLogin
} from "@TicketMundo/login-tm-library";
import Swal from "sweetalert2/dist/sweetalert2.js";
import $ from 'jquery';

SwiperCore.use([Navigation]);
const EventBanner = (props) => {
    const [heightVideos, setHeightVideos] = useState(0);
    const { t } = useTranslation();
    const [controls, setControls] = useState(true);
    const [isFavourite, setFavourite] = useState(false);
    let SESSION = sessionStorage.getItem("session") ? JSON.parse(sessionStorage.getItem("session")) : {}
    //console.log('SESSION',SESSION?.token?.Value)
    const {
        global,
        eventId,
        event,
        eventData,
        locationsNum,
        selectedFunction,
        isMultiCity,
        hourFormat,
        shortUrl,
        setShowModal,
    } = props;
    const refCarrusel = useRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    
    const HandleHeight = () => {
        if (!refCarrusel.current) return;
        const img = refCarrusel?.current.getElementsByTagName("img")[0];
        if (img && img.getBoundingClientRect().height !== heightVideos) {
            setHeightVideos(img.getBoundingClientRect().height);
        }
    };

    const setFavouriteEvent= () => {
        if(isFavourite){
            let favrequest =  Axios.delete(`${process.env.REACT_APP_URL_API}eventosFavoritos/${eventId}`, {
                headers: {
                    'Content-Type': 'applcation/json',
                    Authorization: `Bearer ${SESSION?.token?.Value}`
                }
            }).then(res =>{
                console.log(res)
                
            })
        }else{
            let favrequest = Axios.put(`${process.env.REACT_APP_URL_API}eventosFavoritos/${eventId}`, '',{
                headers: {
                    'Content-Type': 'applcation/json',
                    Authorization: `Bearer ${SESSION?.token?.Value}`
                }
            }).then(res =>{
                console.log(res)
                /*Swal.fire({
                    customClass: {
                        title: "titleCode",
                    },
                    title: `Error in get currency`,
                });*/
            })
        }
        /*GET api/eventosFavoritos?page=1&pageSize=15*/
        setFavourite(!isFavourite)
    };
    useEffect(() => {
        window.addEventListener("resize", HandleHeight);
        return () => {
            window.removeEventListener("resize", HandleHeight);
        };
    }, []);

    useEffect(() => {
        setFavourite((event?.EsFavorito == null || event?.EsFavorito == false) ? false : true)
    }, []);

    useEffect(() => {
        HandleHeight();
    }, [HandleHeight]);
    const erroimg =() =>{
        console.log('imagen responsive del evento da#ada')
        $("#blocore").attr('src',`https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_${eventId}_517_262.jpeg`);
        
    }
    const selectedLabel =
        selectedFunction && selectedFunction.Extra ? (
            <div id="labelSelect" className="label">
                {selectedFunction.Extra}
            </div>
        ) : null;
    let video = event.VideoUrl || "";
    video = video.replace("watch?v=", "embed/");
    return (
        <>
            <div className="banner-evento clear-fl">
                <div className="Carrusel-banner" ref={refCarrusel}>
                    {
                        ($(window).width() < 769 && (event?.Configuracion)) ?
                        <img id={`blocore`} src={ (`https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/${JSON.parse(event?.Configuracion).ImagenEvento}` )} 
                        onError={()=> erroimg()} alt="Imagen Evento"/>
                        :
                        <figure>
                            <img src={(`https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_${eventId}_517_262.jpeg` || `/images/evento/img-evento.jpg`)} alt="Imagen Evento" />
                        </figure>
                    }
               
                   {/*  <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        navigation={controls}

                        // breakpoints={{
                        //     768: {
                        //         slidesPerView: 2,
                        //         spaceBetween: 30
                        //     },
                        //     1024: {
                        //         slidesPerView: 2,
                        //         spaceBetween: 30
                        //     }
                        // }}
                    >
                        <SwiperSlide>
                            <figure>
                                <img
                                    src={
                                        `https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_${eventId}_517_262.jpeg` ||
                                        "/images/evento/img-evento.jpg"
                                    }
                                    alt="Imagen Evento"
                                />
                            </figure>
                        </SwiperSlide>
                       
                        {video !== "" ? (
                            <SwiperSlide>
                                <ReactPlayer
                                    url={video}
                                    className="react-player"
                                    width="100%"
                                    height={heightVideos}
                                    controls={true}
                                    autoplay={false}
                                    onPlay={() => {
                                        setControls(false);
                                    }}
                                    onPause={() => {
                                        setControls(true);
                                    }}
                                    onEnded={() => {
                                        setControls(true);
                                    }}
                                />
                            </SwiperSlide>
                        ) : null}
                    </Swiper>*/}
                    {   
                        global?.LoginActive == true ?
                            SESSION?.token ?
                            <button
                                className="favorite-button"
                                onClick={() => {
                                    setFavouriteEvent();
                                }}
                            >
                                {
                                    (isFavourite == true )?
                                    <FullHeart />
                                    :
                                    <EmptyHeart/>
                                }
                            </button>
                            :
                            <label className="favorite-button" >
                                <EmptyHeart/>
                                <span style={{ display: "none" }}><ButtonLogin /></span>
                            </label>
                        :null
                    }
                   
                    <button
                        className={ global?.LoginActive == true ?"share-button":"favorite-button"}
                        onClick={() => {
                            shortUrl();
                            setShowModal(true);
                        }}
                    >
                        <Share />
                    </button>
                </div>
                <div className="info-evento color-titulos">
                    <div className="title sub-title-bold ">{event.Nombre}</div>
                    {locationsNum > 1 ? (
                        selectedFunction ? (
                            <p className="tipo-funcion">
                                {selectedFunction.RecintoNombre}
                            </p>
                        ) : (
                            <p className="tipo-funcion">
                                {t("multipleLocations")}
                            </p>
                        )
                    ) : (
                        <p className="tipo-funcion">
                            <span>{eventData.venue}</span>
                        </p>
                    )}
                    <div className="label_cont">
                        {props.event.Categorias && props.event.Categorias.length > 0 ? (
                            props.event.Categorias.map((c) => (
                            <span className="label">{c.Nombre}</span>
                            ))
                        ) : (
                            <span className="label">{event.CategoriaNombre}</span>
                        )}
                    </div>
                    {selectedLabel}

                    {selectedFunction ? (
                        <div id="fecha-r" className="active">
                            <span>
                                {moment
                                    .months()

                                    [
                                        moment(selectedFunction.Fecha).month()
                                    ].toLocaleUpperCase()
                                    .substring(0, 3)}
                            </span>
                            <p>{moment(selectedFunction.Fecha).date()}</p>
                        </div>
                    ) : eventData.from ? (
                        <div id="fecha-r" className="active">
                            <div>Desde</div>
                            <span>
                                {moment
                                    .months()

                                    [
                                        moment(eventData.from).month()
                                    ].toLocaleUpperCase()
                                    .substring(0, 3)}
                            </span>
                            <p>{moment(eventData.from).date()}</p>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default EventBanner;
